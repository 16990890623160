import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { Modal } from "clutch/src/Modal/Modal.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { postData } from "@/__main__/get-data.mjs";
import { accountRefs } from "@/app/account.refs.mjs";
import { showSnackbar, writeSettings } from "@/app/actions.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { getGameProfileImg } from "@/app/util.mjs";
import noopModel from "@/data-models/no-op.mjs";
import { disconnectProfile, updatePrivacy } from "@/game-val/actions.mjs";
import * as API from "@/game-val/api.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import { getProfileIcon } from "@/game-val/static.mjs";
import { getAuthorizationHeaders } from "@/game-val/valorant-fetch-profile.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import {
  AccountManager,
  AccountTile,
  BackgroundImage,
  ContentWrapper,
  Divider,
} from "@/hub-settings/AccountsVal.style.jsx";
import { setShowManageAccountModal } from "@/hub-settings/actions.mjs";
import CheckIcon from "@/inline-assets/check.svg";
import CloseIcon from "@/inline-assets/close-icon.svg";
import ExclamationIcon from "@/inline-assets/exclamation-mark.svg";
import GearIcon from "@/inline-assets/gear.svg";
import RiotLogo from "@/inline-assets/riot-logo.svg";
import { devError } from "@/util/dev.mjs";
import useDropdownRef from "@/util/use-dropdown-ref.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const useProfiles = () => {
  const {
    settings: {
      loggedInAccounts: { valorant },
    },
  } = useSnapshot(readState);

  return useMemo(() => {
    const profiles = [];
    for (const key in valorant) {
      const profile = valorant[key];
      profiles.push(formatProfile(profile, key));
    }

    return profiles;
  }, [valorant]);
};

function ManageAccountModal() {
  const manageAccountModalRef = useRef();
  const profiles = useProfiles();
  const {
    volatile: { showManageAccountModal },
  } = useSnapshot(readState);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const { t } = useTranslation();
  const { isLoggedIn } = useSnapshot(accountRefs);

  const profile = profiles.find((p) => p.key === showManageAccountModal);

  useEffect(() => {
    return () => {
      setShowManageAccountModal(null);
    };
  }, []);

  useEffect(() => {
    if (!profile) {
      manageAccountModalRef.current.close();
    }
    if (manageAccountModalRef.current && showManageAccountModal) {
      // open modal and prevent annoying focus selection border
      if (!manageAccountModalRef.current.open) {
        manageAccountModalRef.current?.showModal();
        manageAccountModalRef.current.blur();
      }
    } else {
      manageAccountModalRef.current.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, showManageAccountModal]);

  const handleModalClose = useCallback(() => {
    setShowManageAccountModal(null);
  }, []);

  const handlePrivacyToggle = useCallback(
    async (isPrivate) => {
      const { gameName, tagLine } = profile;

      const headers = await getAuthorizationHeaders();

      if (API.shouldShadow()) {
        postData(
          API.updateProfilePrivacyV2({
            gameName,
            tagLine,
            private: isPrivate,
          }),
          noopModel,
          undefined,
          { headers },
        );
      }

      await postData(
        API.updateProfilePrivacy({
          gameName,
          tagLine,
          private: isPrivate,
        }),
        noopModel,
        undefined,
        { headers },
      )
        .then(() => {
          updatePrivacy(gameName, tagLine, isPrivate);
          showSnackbar({
            priority: "high",
            id: `valorant-privacy-toggle`,
            Icon: () => (
              <div style={{ color: "var(--turq)" }}>
                <CheckIcon />
              </div>
            ),
            text: [
              "valorant:successfullySetPrivacy",
              `Successfully set to {{privacyStatus}}.`,
              {
                privacyStatus: isPrivate ? "private" : "public",
              },
            ],
            dismissable: false,
            closeAfter: 5000,
          });
        })
        .catch((e) => {
          devError("Error setting valorant profile privacy", e);
          showSnackbar({
            priority: "high",
            id: `valorant-privacy-toggle`,
            Icon: () => (
              <div style={{ color: "var(--red)" }}>
                <ExclamationIcon />
              </div>
            ),
            text: [
              "valorant:errorSettingPrivacy",
              `Error setting privacy to {{privacyStatus}}.`,
              {
                privacyStatus: isPrivate ? "private" : "public",
              },
            ],
            dismissable: false,
            closeAfter: 5000,
          });
        });
    },
    [profile],
  );

  const closeConfirmPopup = useCallback(() => {
    setShowConfirmPopup(false);
  }, []);

  const toggleConfirmPopup = useCallback(() => {
    setShowConfirmPopup(!showConfirmPopup);
  }, [showConfirmPopup]);

  const handleConnectionDisconnect = useCallback(async () => {
    const { gameName, tagLine, userAccountId, region, isPrivate } = profile;
    if (userAccountId) {
      const shouldShadow = API.shouldShadow();
      const headers = await getAuthorizationHeaders();
      if (typeof isPrivate === "boolean") {
        if (shouldShadow) {
          postData(
            API.updateProfilePrivacyV2({ gameName, tagLine, private: true }),
            noopModel,
            undefined,
            { headers },
          );
        }
        await postData(
          API.updateProfilePrivacy({ gameName, tagLine, private: true }),
          noopModel,
          undefined,
          { headers },
        );
        updatePrivacy(gameName, tagLine, true);
      }
      if (shouldShadow) {
        postData(
          API.disconnectRiotAccountV2({ gameName, tagLine, region }),
          noopModel,
          undefined,
          { headers },
        );
      }
      await postData(
        API.disconnectRiotAccount({ gameName, tagLine, region }),
        noopModel,
        undefined,
        { headers },
      );
      disconnectProfile(gameName, tagLine);
    }
    writeSettings([
      "loggedInAccounts",
      GAME_SHORT_NAMES[GAME_SYMBOL_VAL],
      profile.key,
    ]);
    /* TODO: implement RSO disconnection mutation request */
    setShowManageAccountModal(null);
  }, [profile]);

  const confirmPopupRef = useDropdownRef({
    onClickOutside: closeConfirmPopup,
    onWindowResize: closeConfirmPopup,
    onAssignNode: () => {},
  });

  return (
    <Modal ref={manageAccountModalRef} onModalClose={handleModalClose}>
      <AccountManager>
        <Card
          title={t("valorant:manageAccount.title", "Manage Account")}
          headerControls={
            <div className="flex gap-3">
              <span className="seperator" />
              <span className="close-icon" onClick={handleModalClose}>
                <CloseIcon />
              </span>
            </div>
          }
        >
          <AccountTile>
            <BackgroundImage
              style={{
                backgroundImage: `url(${getProfileIcon(
                  profile?.playerCardId,
                  profile?.lastAgentName,
                )})`,
              }}
            />
            <div className={"gradient"} />
            <ContentWrapper>
              <div className="flex gap-4 align-center">
                <img
                  alt={`${profile?.gameName}#${profile?.tagLine}`}
                  className="icon"
                  src={getProfileIcon(
                    profile?.playerCardId,
                    profile?.lastAgentName,
                  )}
                  width={"var(--sp-11)"}
                  height={"var(--sp-11)"}
                />
                <p className="shade0">
                  {profile?.gameName}
                  {profile?.tagLine && (
                    <span className="shade1"> #{profile.tagLine}</span>
                  )}
                </p>
              </div>
            </ContentWrapper>
          </AccountTile>
          {isLoggedIn ? (
            <>
              <Divider />
              <div className="flex section gap-6">
                <div className="flex column gap-2">
                  <p className="type-subtitle2">
                    {t(
                      "valorant:manageAccount.profileVisibilityTitle",
                      "Profile Visibility",
                    )}
                  </p>
                  <p className="type-body2 color-shade1">
                    {t(
                      "valorant:manageAccount.profileVisibilityDescription",
                      "By setting your account to 'Private', only you will be able to view your profile and stats",
                    )}
                  </p>
                </div>
                <ButtonGroup>
                  <Button
                    active={!(profile?.isPrivate ?? true)}
                    onClick={() => handlePrivacyToggle(false)}
                  >
                    {t("common:public", "Public")}
                  </Button>
                  <Button
                    active={profile?.isPrivate ?? true}
                    onClick={() => handlePrivacyToggle(true)}
                  >
                    {t("common:private", "Private")}
                  </Button>
                </ButtonGroup>
              </div>
            </>
          ) : null}
          <Divider />
          <div className="section flex gap-6">
            <div className="flex row gap-4 fit-content">
              <div className="logo-container">
                <RiotLogo />
              </div>
              <div className="flex column gap-2">
                <p className="type-subtitle2">
                  {t(
                    "valorant:manageAccount.connectionTitle",
                    "Riot Connections",
                  )}
                </p>
                <p className="type-body2 color-shade1">
                  {t(
                    "valorant:manageAccount.connectionDescription",
                    "By disconnecting your Riot account from Blitz, you will no longer be able to view your profile and stats.",
                  )}
                </p>
              </div>
            </div>
            <div className="confirm-popup-wrapper">
              <Button className="fit-content" onClick={toggleConfirmPopup}>
                {t("common:disconnect", "Disconnect")}
              </Button>
              {showConfirmPopup ? (
                <div
                  className="confirm-popup flex column gap-2"
                  ref={confirmPopupRef}
                >
                  <p className="type-body1-form--active">
                    {t("common:areYouSure", "Are you sure?")}
                  </p>
                  <p className="type-body2 color-shade1">
                    {t(
                      "valorant:manageAccount.delinkYourAccount",
                      "This will de-link your Riot account.",
                    )}
                  </p>
                  <div className="flex gap-4">
                    <Button
                      emphasis="high"
                      onClick={() => {
                        toggleConfirmPopup();
                        handleConnectionDisconnect();
                      }}
                    >
                      {t("Confirm")}
                    </Button>
                    <Button onClick={toggleConfirmPopup}>
                      {t("common:cancel", "Cancel")}
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Card>
      </AccountManager>
    </Modal>
  );
}

const renderPrivacy = (profile) => {
  return (
    <span onClick={() => setShowManageAccountModal(profile.key)}>
      <GearIcon />
    </span>
  );
};

const formatProfile = (profile, key) => {
  return {
    key: profile.internalUuid || key,
    displayName: profile.gameName,
    tag: profile.tagLine,
    region: profile.region,
    avatar: getGameProfileImg(GAME_SYMBOL_VAL, profile),
    isPrivate: profile.isPrivate,
    userAccountId: profile.userAccountId,
    gameName: profile.gameName,
    tagLine: profile.tagLine,
    internalUuid: profile.internalUuid,
  };
};

export default function AccountsVal() {
  const profiles = useProfiles();
  return (
    <Accounts
      profiles={profiles}
      gameShort={GAME_SHORT_NAMES[GAME_SYMBOL_VAL]}
      autoDetectText={[
        "val:settings.autoDetectProfile",
        "Hey! I can automatically detect your profile. Just open VALORANT and log into the account you want to connect.",
      ]}
      showDeleteAction={false}
      renderOtherActionItems={renderPrivacy}
      renderOtherItems={ManageAccountModal}
    />
  );
}
